import React, { useEffect, useState } from "react";
import { BsArrowRight, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaShare, FaPrint, FaDownload, FaCopy, FaEdit } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

import { MdDeleteOutline, MdVerified } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaInstagram, FaTwitterSquare, FaYoutube } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import {
  fetchStoreDetails,
  delete_user_store,
} from "../../../../api/userRegestration/CollectionForm";
import { RxCross1 } from "react-icons/rx";
import EditStore from "./EditStore";
//
export default function PallMain() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDivA, setShowDivA] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [storeDetails, setStoreDetails] = useState();
  const { storeId } = useParams();

  useEffect(() => {
    fetchStoreDetails(storeId)
      .then((response) => {
        console.log("API Response:", response);
        setStoreDetails(response.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [storeId]);
  // deleeting bussiness card
  const delete_bussinesscard = async () => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this  your store?"
      );

      if (!isConfirmed) {
        return;
      }

      const produtdata = {
        storeId: storeId,
      };

      const Product = await delete_user_store(produtdata);
      console.log("user action:", Product);

      navigate("/findproduct/viewregestration");
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };
  const toggleDiv = () => {
    setShowDivA(!showDivA);
  };

  const updateProductDetails = (newDetails) => {
    setStoreDetails(newDetails);
  };

  return (
    <>
      {showDivA ? (
        <div className="a">
          <EditStore
            storeDetails={storeDetails}
            storeId={storeId}
            toggleview={toggleDiv}
            updateProductDetails={updateProductDetails}
          />
        </div>
      ) : (
        <div className="b">
          <div className="ml-4 mt-6">
            <div>
              {storeDetails && (
                <>
                  <div className="page-m-(a) ">
                    <div>
                      {/* speed dial  */}
                      <div className="fixed top-6 end-6 group">
                        <button
                          type="button"
                          onClick={toggleMenu}
                          className="flex items-center justify-center text-white bg-[#cf3917] rounded-full w-14 h-14  shark:bg-blue-600 shark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none shark:focus:ring-blue-800"
                        >
                          <span className="sr-only">Open actions menu</span>
                          <div
                            className={`w-5 h-5 transition-transform ${
                              isMenuOpen ? "rotate-45" : ""
                            }`}
                            style={{
                              transform: isMenuOpen
                                ? "rotate(45deg)"
                                : "rotate(0deg)",
                            }}
                          >
                            {isMenuOpen ? (
                              <RxCross1 className="w-5 h-5" />
                            ) : (
                              <CiEdit />
                            )}
                          </div>
                        </button>
                        {isMenuOpen && (
                          <div className="flex flex-col items-center mt-4 space-y-2">
                            <div
                              id="speed-dial-menu-top-right"
                              className="flex flex-col items-center  mt-4 space-y-2"
                            >
                              <button
                                onClick={delete_bussinesscard}
                                type="button"
                                data-tooltip-target="tooltip-share"
                                data-tooltip-placement="left"
                                className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-90 bg-white rounded-full border border-gray-200 shark:border-gray-600 shadow-sm shark:hover:text-white shark:text-gray-400 hover:bg-red-300 shark:bg-gray-700 shark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none shark:focus:ring-gray-400"
                              >
                                <MdDeleteOutline className="w-5 h-5" />
                              </button>

                              <button
                                onClick={toggleDiv}
                                type="button"
                                data-tooltip-target="tooltip-print"
                                data-tooltip-placement="left"
                                className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 shark:border-gray-600 shadow-sm shark:hover:text-white shark:text-gray-400 hover:bg-green-100 shark:bg-gray-700 shark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none shark:focus:ring-gray-400"
                              >
                                <FaEdit />
                                <span className="sr-only">Print</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* speed dial end   */}
                    </div>

                    <h1 className="text-center font-all font-extrabold text-3xl py-4 ">
                      {storeDetails.name}
                    </h1>
                    <div className="m-item-1">
                      <div className="flex flex-row">
                        <div className="a item-1 img w-[48rem]  bg-gray-100 flex items-center">
                          <img
                            src={storeDetails.banner}
                            className="rounded-md shadow-md drop-c cursor-pointer h-[12rem] w-[12rem] mx-auto "
                            alt=""
                          />
                        </div>

                        <div className="flex flex-col items-center">
                          <div className="c item-2 location  border-[1px] border-[#D2D2D2] rounded-md shadow-md drop h-max w-[284px] ml-[9px]">
                            <h1 className="font-all font-extrabold text-[#263238] ml-4 mt-6">
                              Location
                            </h1>
                            <p className="font-all w-[202px] ml-4  mt-[12px]">
                              {storeDetails.location}
                            </p>

                            <button className=" mt-[15px] flex items-center gap-[10px] m-auto mx-auto justify-center bg-[#F8F8F8] shadow-md p-[6px] w-[11rem] rounded-lg transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white mb-3">
                              Get Direction{" "}
                              <span>
                                <BsArrowRight className="text-[20px]" />
                              </span>{" "}
                            </button>
                          </div>

                          <div className="c-3">
                            <div className="b border-[1px] border-[#D2D2D2]   lg:mt-[31px] ml-[9px] rounded-md shadow-md drop w-[284px]">
                              <h2 className="mt-3 text-[#263238] font-semibold ml-3 text-[17px]">
                                Awards & Certificates
                              </h2>
                              {/*  */}
                              <h4 className=" text-[#263238] mt-[18px] ml-3 font-semibold">
                                Certificate of Recognization
                              </h4>
                              <p
                                className="italic text-[#263238] ml-3"
                                style={{ fontFamily: "fangsong" }}
                              >
                                {storeDetails.certificates.map(
                                  (certificate, index) => (
                                    <li className="italic" key={index}>
                                      {certificate}
                                    </li>
                                  )
                                )}
                              </p>

                              <h4 className=" text-[#263238] mt-[18px] ml-3 font-semibold">
                                Awards of Excellence
                              </h4>
                              <p
                                className=" text-[#263238] ml-3 italic"
                                style={{ fontFamily: "fangsong" }}
                              >
                                {storeDetails.certificates.map(
                                  (certificate, index) => (
                                    <li key={index}>{certificate}</li>
                                  )
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="B-m-item-2 mt-6 flex flex-row gap-[3rem]">
                      <div className="item-b-1">
                        <div className="flex flex-row lg:gap-[16rem] lg:mt-[10px]">
                          <h1 className="font-all font-extrabold text-[28px]">
                            {storeDetails.name}
                          </h1>
                          <div className="flex flex-row items-center gap-[13px]">
                            <span className="h-max text-[15px] gap-[9px] text-[#263238] flex flex-row items-center bg-[#E98F92] p-[2px] rounded-md">
                              {" "}
                              <span className="relative left-[3px]">
                                {" "}
                                Verified{" "}
                              </span>{" "}
                              <MdVerified className="text-[#263238]" />
                            </span>

                            <div className="flex items-center border-x-2 border-black px-3">
                              <FaStar className="w-[13px] h-5 text-yellow-300" />
                              <FaStar className="w-[13px] h-5 text-yellow-300" />
                              <FaStar className="w-[13px] h-5 text-yellow-300" />
                              <FaStar className="w-[13px] h-5 text-yellow-300" />
                              <FaStar className="w-[13px] h-5 text-gray-300 shark:text-gray-500" />
                              <p className="ml-2 text-sm font-medium text-[#263238] shark:text-gray-400">
                                4/5
                              </p>
                            </div>

                            <p className="font-bold font-all decoration-solid text-[#263238]">
                              Reviews
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="item-b-2"></div>
                    </div>
                  </div>

                  <div className="page-m-(b) mt-3 fade-in-left">
                    <div className="flex flex-wrap border-[1px] border-[#D2D2D2] lg:p-[15px] rounded-md w-[99%]">
                      <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8   border-opacity-60">
                        <h2 className="text-lg sm:text-xl text-gray-900 font-semibold font-all title-font mb-2">
                          Timing
                        </h2>
                        <p className="leading-relaxed text-base font-all ">
                          {storeDetails.timing}
                        </p>
                        {/* <p className="leading-relaxed text-base font-all ">
                 Monday to Friday
               </p> */}
                      </div>
                      <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
                        <h2 className="text-lg sm:text-xl text-gray-900 font-all font-semibold mb-2">
                          Year of Establishment
                        </h2>
                        <p className="leading-relaxed text-base lg:text-[18px] ">
                          since : {storeDetails.established}
                        </p>
                        {/* <span className="font-all">founded in 2003</span> */}
                      </div>
                      <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
                        <h2 className="text-lg sm:text-xl text-gray-900 font-all font-semibold mb-2">
                          Contact us
                        </h2>
                        <p className="leading-relaxed text-base flex flex-row items-center lg:gap-[8px] ">
                          <BsTelephone />
                          {storeDetails.phoneNumber}
                        </p>
                        <span className="flex flex-row items-center lg:gap-[8px]">
                          {" "}
                          <AiOutlineMail />
                          {storeDetails.mail}
                        </span>
                      </div>
                      <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8  border-l-2 border-gray-200 border-opacity-60">
                        <h2 className="text-lg sm:text-xl text-gray-900 font-all font-semibold mb-2">
                          Follow us
                        </h2>
                        <div className="flex flex-row-reverse items-center lg:gap-[7px] justify-center lg:mt-[13px]">
                          <FaInstagram className="lg:text-[33px] text-[#E1306C]" />
                          <FaTwitterSquare className="lg:text-[33px] text-[#1DA1F2] " />
                          <FaYoutube className="lg:text-[33px] text-[#FF0000]" />
                          <AiFillFacebook className="lg:text-[35px] rounded-md  text-[#445c8f]" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-center items-center lg:gap-[74px]"></div>
                  <div className="flex flex-row gap-3">
                    <div className="a border-[#D2D2D2] border-[1px] rounded-md lg:w-[48rem] lg:mt-[2rem] mb-4">
                      <h1 className="font-all font-semibold text-[#263238] ml-8 mt-4 text-[21px]">
                        Frequently Asked Question
                      </h1>

                      <h3 className="font-all font-semibold ml-[13px] mt-[14px] text-[#141414] lg:text-[18px]">
                        1. What is the nearest Landmark ?{" "}
                      </h3>
                      <p className="font-all ml-[28px] text-[#263238] mt-[8px] ">
                        The establishment is 1 mile away from the xyz monument ,
                        city A.
                      </p>

                      <h3 className="font-all font-semibold ml-[13px] mt-4 text-[#141414] lg:text-[18px] ">
                        2. What are the operational hours?
                      </h3>
                      <p className="font-all ml-[28px]">
                        Monday <span>(12:am - 12:pm)</span>{" "}
                      </p>
                      <p className="font-all ml-[28px]">
                        Tuesday <span>(12:am - 12:pm)</span>{" "}
                      </p>
                      <p className="font-all ml-[28px]">
                        Wednesday <span>(12:am - 12:pm)</span>{" "}
                      </p>
                      <p className="font-all ml-[28px]">
                        Thursday <span>(12:am - 12:pm)</span>{" "}
                      </p>
                      <p className="font-all ml-[28px]">
                        Friday <span>(12:am - 12:pm)</span>{" "}
                      </p>
                      <p className="font-all ml-[28px] mb-3">
                        Saturday <span>(12:am - 12:pm)</span>{" "}
                      </p>
                    </div>

                    <div className="b mb-4 border-[1px] border-[#D2D2D2] lg:w-[15rem] rounded-md lg:mt-[31px]">
                      <h2 className=" mt-3 text-[#263238] font-semibold ml-3 text-[17px]">
                        Report an Error
                      </h2>
                      <p className="font-all mt-4 p-[10px]">
                        We aim to provide you with only the best experience.
                        Incase you are finding any difficulty or problem, leave
                        a message below to let us know!
                      </p>

                      <button className=" mt-[15px] flex items-center gap-[10px] m-auto mx-auto justify-center bg-[#F8F8F8] shadow-md p-[6px] w-[11rem] rounded-lg transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white mb-3">
                        Report now{" "}
                        <span>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                            className=" text-[20px]"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                            ></path>
                          </svg>
                        </span>{" "}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/*  */}
          </div>
        </div>
      )}
    </>
  );
}
