import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { update_product_detail } from "../../api/userRegestration/CollectionForm";

function UpdateFormComponent(details, settoggleview) {
  //
  const { jobId,type } = useParams();
  
  const [isLoading, setIsLoading] = useState(false);
  //
  useEffect(() => {
    console.log("details from nested components", details.details.moq);
  }, [details]);
  //
  const [formData, setFormData] = useState({
    field1: details.details.categorie || "",
    field2: details.details.amount || "",
    field2: details.details.amount || "",
    field3: details.details.moq,
    field4: "",
    field5: details.details.details || "",
    field5: details.details.details || "",
    field6: "",
    field7: "",
    field8: details.details.categorie,
    field9: details.details.serviceName,
    field10: details.details.rate,
    field11: details.details.details,
    field12: details.details.title,
    field13: details.details.profession,
    field14: details.details.salary,
    field15: details.details.experience,
    field16: details.details.description,
    field17: details.details.skills,
    field18: details.details.projectName,
    field19: details.details.companyName,
    field20: details.details.mail,
    field21: details.details.mobileNumber,
    field22: details.details.postTitle,
    field23: details.details.details,
    field24: details.details.mqu,
    

    imageFile: null,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    // update_product_detail
    try {
      setIsLoading(true);
      const product_detail = {
        productId: String(jobId),
        productName: "Seller latop ",
        mediaUrl:
          "https://carwow-uk-wp-3.imgix.net/18015-MC20BluInfinito-scaled-e1666008987698.jpg?auto=format&cs=tinysrgb&fit=clip&ixlib=rb-1.1.0&q=10&w=800",
        details: formData.field5,
        amount: formData.field2,
        mediaType: "MEDIA",
        categorie: formData.field8,
        moq: formData.field3,
        mqu: formData.field24,
        subCategorie: "IT",
      };

      const productdetails = await update_product_detail(product_detail);
      console.log("Product Details:", productdetails.result);
      // setProfiledata(productdetails.result);
      console.log("state updated siccsfully ");
      setIsLoading(false);
      settoggleview(true);
      

      // window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //
  let fields;
  switch (type) {
    case "PRODUCT":
      fields = [
        {
          label: "Product Categories",
          type: "text",
          placeholder: "Write here...",
          name: "field8",
          options: ["Piece (pc)", "Set", "Meter", "Gallon (GL)"],
          value: formData.field8,
          onchange: handleInputChange,
        },
        {
          label: "Product Rate",
          type: "text",
          placeholder: "Write here...",
          name: "field2",
          value: formData.field2,
          onchange: handleInputChange,
        },
        {
          label: "Minimum Order Quantity",
          type: "text",
          placeholder: "Write here...",
          name: "field3",
          value: formData.field3,
          onchange: handleInputChange,
        },
        {
          label: "MQ UNIT",
          type: "select",
          name: "field24",
          options: [
            "Piece (pc)",
            "Set",
            "Meter",
            "Roll",
            "Kilowatts",
            "Watts",
            "Kilogram (Kg)",
            "Gram (g)",
            "Quintal (Qt)",
            "Metric Tonnes",
            "Tonne (T)",
            "Liter (LT)",
            "Milliliter (mL)",
            "Barrel (bbL)",
            "Gallon (GL)",
          ],
          value: formData.field24,
          onchange: handleInputChange,
        },
        {
          label: "Product Details",
          type: "textarea",
          placeholder: "Write here...",
          name: "field5",
          value: formData.field5,
          onchange: handleInputChange,
        },
      ];
      break;
    case "SERVICE":
      fields = [
        {
          label: "Service Categories",
          type: "text",
          placeholder: "Write here...",
          options: ["Piece (pc)", "Set", "Meter", "Gallon (GL)"],
          name: "field8",
          value: formData.field8,
          onchange: handleInputChange,
        },
        {
          label: "Service Name",
          type: "text",
          placeholder: "Write here...",
          name: "field9",
          value: formData.field9,
          onchange: handleInputChange,
        },
        {
          label: "Rate",
          type: "text",
          placeholder: "Write here...",
          name: "field10",
          value: formData.field10,
          onchange: handleInputChange,
        },
        {
          label: "MQ UNIT",
          type: "select",
          name: "field4",
          options: ["Service", "Service", "Service"],
          value: formData.field4,
          onchange: handleInputChange,
        },
        {
          label: "Service Details",
          type: "textarea",
          placeholder: "Write here...",
          name: "field11",
          value: formData.field11,
          onchange: handleInputChange,
        },
      ];
      break;
    case "JOB":
      fields = [
        {
          label: "Job Title",
          type: "text",
          placeholder: "Write here...",
          name: "field12",
          value: formData.field12,
          onchange: handleInputChange,
        },
        {
          label: "Profession Categories",
          type: "select",
          placeholder: "Write here...",
          name: "field13",
          options: ["Service", "Service", "Service"],
          value: formData.field13,
          onchange: handleInputChange,
        },
        {
          label: "Salary Expectation",
          type: "number",
          placeholder: "Write here...",
          name: "field14",
          value: formData.field14,
          onchange: handleInputChange,
        },
        {
          label: "years of exprience",
          type: "text",
          name: "field15",
          value: formData.field15,
          onchange: handleInputChange,
        },
        {
          label: "Resume / CV",
          type: "text",
          placeholder: "Write here...",
          name: "field17",
          value: formData.field17,
          onchange: handleInputChange,
        },
        {
          label: "About Yourself",
          type: "text",
          placeholder: "Write here...",
          name: "field16",
          value: formData.field16,
          onchange: handleInputChange,
        },
        {
          label: "Relevant Skills / Keywords",
          type: "text",
          placeholder: "Write here...",
          name: "field17",
          value: formData.field17,
          onchange: handleInputChange,
        },
      ];
      break;
    case "POST":
      fields = [
        {
          label: "Post Title",
          type: "text",
          placeholder: "Write here...",
          name: "field22",
          value: formData.field22,
          onchange: handleInputChange,
        },
        {
          label: "Description",
          type: "text",
          placeholder: "Write here...",
          name: "field23",
          value: formData.field23,
          onchange: handleInputChange,
        },
      ];
      break;
    case "project":
      fields = [
        {
          label: "Project Name",
          type: "text",
          placeholder: "Write here...",
          name: "field18",
          value: formData.field18,
          onchange: handleInputChange,
        },
        {
          label: "Company Name",
          type: "text",
          placeholder: "Write here...",
          name: "field19",
          value: formData.field19,
          onchange: "",
        },
        {
          label: "Email Address",
          type: "text",
          placeholder: "Write here...",
          name: "field20",
          value: formData.field20,
          onchange: handleInputChange,
        },
        {
          label: "Phone number",
          type: "number",
          placeholder: "Write here...",
          name: "field21",
          value: formData.field21,
          onchange: handleInputChange,
        },
      ];
      break;
    default:
      fields = [];
  }

  // useEffect(() => {
  //   console.log(type, "type");
  // }, []);

  return (
    <>
      <div className="conditional _ _ action _ _ a">
        <div className="conditional _ _ action _ _ a">
          <div className="">
            <form className="form flex justify-center items-center flex-col">
              <div className="flex flex-col mt-[3rem] mb-[2rem]">
          <div className="form">
            <Link to="/findproduct/viewallinput">
              <button className=" font-semibold  text-[#4b5563] text-[20px] mx-4">
                Go Back
              </button>
            </Link>
            <form>
              <div className="flex flex-col mt-[2rem] mb-[2rem]">
                {fields.map((field, index) => (
                  <div key={index} className="form-input flex flex-col w-[47%]">
                    <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                      <label
                        htmlFor={field.name}
                        className={`text text-sm text-black w-max px-2 rounded-md bg-[white] relative top-2.5 ml-2  bg-w-fit-content text-center`}
                      >
                        {field.label}
                      </label>
                      {field.type === "select" ? (
                        <select
                          name={field.name}
                          value={formData[field.name]}
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white p-[10px]"
                          onChange={field.onchange}
                          required
                        >
                          <option value="">Select an option...</option>
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "textarea" ? (
                        <textarea
                          placeholder={field.placeholder}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={field.onchange}
                          rows="7"
                          className="px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white p-[10px]"
                          required
                        ></textarea>
                      ) : (
                        <input
                          type={field.type}
                          placeholder={field.placeholder}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={field.onchange}
                          className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] bg-white p-[10px]"
                          required
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex justify-end mx-auto  mt-2 xs:mt-8 ">
                  <button
                    type="submit"
                    className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#cb2229] rounded-full focus:outline-none focus:bg-gray-600"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="  bg-[#CB2229] flex flex-row justify-center items-center gap-2 text-white rounded-full font-all p-[6px] my-[1rem] w-[25rem]   transform hover:scale-105 duration-500 ease-in-out relative"
              >
                Update
              </button>
            </form>
          </div>
        </div>
        </form>
      </div>
      </div>
      </div>
    </>
  );
}

export default UpdateFormComponent;
