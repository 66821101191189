import React, { useState } from "react";
import { useWallet } from "./Context/WalletContext";
import { registerUserdata } from "../../api/userRegestration/CollectionForm";

function WalletForm() {
  // Define initial state for form fields
  const { profiledata } = useWallet();
// 
  const [formData, setFormData] = useState({
    vpaAddress: "",
    country: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    stateProvince: "",
    postalCode: "",
    taxStatus: "",
    billingInfo: ""
  });

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async(event) => {
    event.preventDefault();
    //wallet user from handling 
    const userData = {
        "name":`${profiledata.name}`,
        "userName": "",
        "email": `${profiledata.email}`,
        "countryCode": "",
        "mobileNumber": `${profiledata.mobileNumber}`,
        "gender": "",
        "bio": `${profiledata.bio}`,
        "dob": "",
        "identification": `${profiledata.identification}`,
        "country": formData.country,
        "city": formData.city,
        "state": formData.stateProvince,
        "zipCode": formData.postalCode,
        "addressLine1":formData.streetAddress1,
        "addressLine2": formData.streetAddress2,
        "interest": [profiledata.interest],
        "facebook": "",
        "twitter": "",
        "instagram": "",
        "linkedIn": "",
        "location": "",
        "profilePic": `${profiledata.profilePic}`,
        "coverPic": `${profiledata.coverPic}`
    }
    const registeredUser = await registerUserdata(userData);
    console.log("Registered User:", registeredUser, "result");
    // Do something with the form data, like sending it to a server
    console.log(formData);
  };

  return (
    <>
      <div className="flex flex-col items-center fade-in-right">
        <form onSubmit={handleSubmit}>
          {/* Map over the inputFields array to render input fields dynamically */}
          {Object.keys(formData).map((fieldName, index) => (
            <div key={index} className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[18rem]">
              <label
                htmlFor={fieldName}
                className="text text-sm text-black w-max px-3 rounded-md bg-[white] relative top-2.5 ml-2 bg- w-fit-content text-center"
              >
                {fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              </label>
              <input
                type="text"
                placeholder={`Enter ${fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}`}
                name={fieldName}
                value={formData[fieldName]}
                onChange={handleInputChange}
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] dark:bg-white rounded-md focus:border-[1px] p-[10px]"
              />
            </div>
          ))}

          <button type="submit" className="mb-6 bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[1rem] w-[18rem] transform hover:scale-105 duration-500 ease-in-out relative">
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
}

export default WalletForm;
