import React, { useEffect, useState } from "react";
import ItemTable from "../../../../../Profile/EditableComponents/ItemTable";
import {
  userInput_products,
  userInput_job,
  userInput_services,
  userInput_post,
  userInput_project,
} from "../../../../../../api/userRegestration/UserProfileResponse";
import { useWallet } from "../../../../../Wallet/Context/WalletContext";

// Constructor function for Product API response

const createProduct = (data) => {
  console.log(data._id);
  return {
    id: data._id,
    name: data.productName,
    createat: data.createdAt,
    updateat: data.updatedAt,
  };
};

// Constructor function for Job API response
const createJob = (data) => {
  console.log(data);

  return {
    id: data._id,
    name: data.profession,
    createat: data.createdAt,
    updateat: data.updatedAt,
  };
};

// Constructor function for Service API response
const createService = (data) => {
  console.log(data);

  return {
    id: data._id,
    name: data.serviceName,
    createat: data.createdAt,
    updateat: data.updatedAt,
  };
};

// Constructor function for Post API response
const createPost = (data) => {
  console.log(data.userId, data.ownerId, data._id);

  return {
    id: data._id,
    name: data.postTitle,
    createat: data.createdAt,
    updateat: data.updateAt,
  };
};

// Constructor function for Project API response
const createProject = (data) => {
  console.log(data);

  return {
    id: data._id,
    name: data.projectName,
    createat: data.createdAt,
    updateat: data.updateAt,
  };
};

const InputTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const accessToken = localStorage.getItem("token");

  const tabsData = [
    {
      label: "Product",
      type: "PRODUCT",
      apiFunction: userInput_products,
      createItem: createProduct,
    },
    {
      label: "Service",
      type: "SERVICE",
      apiFunction: userInput_services,
      createItem: createService,
    },
    {
      label: "Jobs",
      type: "JOB",
      apiFunction: userInput_job,
      createItem: createJob,
    },
    {
      label: "Post",
      type: "POST",
      apiFunction: userInput_post,
      createItem: createPost,
    },
    {
      label: "Project",
      type: "PROJECT",
      apiFunction: userInput_project,
      createItem: createProject,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [activeTabIndex]);

  const fetchData = async () => {
    try {
      const response = await tabsData[activeTabIndex].apiFunction(accessToken);
      // Transform the response data using the constructor function
      const transformedData = response.map(tabsData[activeTabIndex].createItem);
      // setData(transformedData);
      setinput_tab_values(transformedData)
    } catch (error) {
      console.error(
        `Error fetching data for ${tabsData[activeTabIndex].type}:`,
        error
      );
      setData([]); // Handle errors by setting an empty array or other default value
    }
  };
  const { input_tab_values, setinput_tab_values } = useWallet();

  return (
    <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] fade-in-left">
      <div className="ml-4 flex space-x-3 border-b justify-center gap-[5rem] xs:gap-[0.5rem]">
        {tabsData.map((tab, idx) => (
          <button
            key={idx}
            className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
              idx === activeTabIndex
                ? "border-[#CB2229]"
                : "border-transparent hover:border-gray-200"
            }`}
            onClick={() => setActiveTabIndex(idx)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="">
        <ItemTable data={input_tab_values} selectedType={tabsData[activeTabIndex].type} />
      </div>
    </div>
  );
};

export default InputTabs;
